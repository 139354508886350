<template>
    <div>
        <div class="container py-5 my-0 my-sm-5 px-4">
                <div class="row">
                    <div class="col-12">
                        <div class="row align-items-center mb-0 pb-5 mb-sm-4">
                            <div class="col-6 col-sm-6 col-md-6 text-left">
                                <div class="logo-wrapper"><img src="../../../assets/img/bau-logo-for-light.png"/></div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 text-right"><h4 class="mb-0">{{$t('e_registration_system')}}</h4></div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="text-center py-5 my-5 fadeIn h-0 d-block h-auto show">
                            <div class="mb-5">
                                <i class="ri-checkbox-circle-line ri-6x text-success"></i>
                            </div>
                            <h4>E-Kayıt İşleminiz Başarıyla Tamamlanmıştır.</h4>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

export default {

}
</script>
